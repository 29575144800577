import React, { useState, useEffect } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Sidebar from "../components/Sidebar";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import { BASE_URL } from "../env";
import AddBranchModal from "./AddBranchModal";
import { MdModeEdit } from "react-icons/md";
import { Modal, Button, Form, Pagination } from "react-bootstrap";

const AdminBranchList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [openSidebarToggle, setOpenSidebarToggle] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [branchesPerPage] = useState(20);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState({
    id: null,
    branchName: "",
    branchCode: "",
  });

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/branches`);

      setBranches(response.data);
      setFilteredBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    const filtered = branches.filter((branch) =>
      branch.branchName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBranches(filtered);
    setCurrentPage(1);
  }, [searchQuery, branches]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleAddBranchClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEditBranch = (branch) => {
    setSelectedBranch(branch);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setEditModalOpen(false);
    setSelectedBranch(null);
  };

  const handleSaveChanges = async () => {
    if (selectedBranch) {
      try {
        const response = await axios.put(`${BASE_URL}/api/admin/editbranch`, {
          branchId: selectedBranch.id,
          branchName: selectedBranch.branchName,
          branchCode: selectedBranch.branchCode,
        });
        if (response.status === 200) {
          fetchBranches();
          handleCloseEditModal();
        } else {
          console.error("Failed to update branch details");
        }
      } catch (error) {
        console.error("Error updating branch details:", error);
      }
    }
  };

  const indexOfLastBranch = currentPage * branchesPerPage;
  const indexOfFirstBranch = indexOfLastBranch - branchesPerPage;
  const currentBranches = filteredBranches.slice(
    indexOfFirstBranch,
    indexOfLastBranch
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredBranches.length / branchesPerPage);

  return (
    <div className="dashboard-over">
      <div className="d-flex">
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
        />
        <div
          className="container-fluid"
          style={{
            overflowY: "scroll",
            height: "100vh",
            scrollbarWidth: "none",
          }}
        >
          <DashboardHeader OpenSidebar={OpenSidebar} />
          <div className="search-container mt-3">
            <button
              className="btn btn-addCredential"
              onClick={handleAddBranchClick}
            >
              Add Branch
            </button>
            <div
            
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
             
            }}
          >
            <div className="search-input-wrapper">
              <input
                type="text"
                placeholder="Search by branch name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
              <span className="search-icon">
                <IoSearch />
              </span>
            </div>
          </div>
          </div>
          
          <div className="mt-5 text-center">
            
              {/* <div class="TableHeaderMain mb-3">
                <h6 class="TableHeaderText">Branch List</h6>
              </div> */}
              <h5 className="pb-3" class="TableHeaderText">Branch List</h5>
              <div className="table-responsive py-1">
                <table  className="mx-auto table table-striped table-list">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Branch Code</th>
                      <th>Branch Name</th>
                      {/* Add this column */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentBranches.length > 0 ? (
                      currentBranches.map((branch, index) => (
                        <tr key={branch.id}>
                          <td>
                            {index + 1 + (currentPage - 1) * branchesPerPage}
                          </td>
                          <td>{branch.branchCode}</td>{" "}
                          <td>{branch.branchName}</td>
                          {/* Display branch code */}
                          <td>
                            <button
                              className="btn btn-edit"
                              title="Edit"
                              onClick={() => handleEditBranch(branch)}
                            >
                              <MdModeEdit />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No branches found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            
            <Pagination className="me-auto custom-pagination">
              <Pagination.First onClick={() => paginate(1)} />
              <Pagination.Prev onClick={() => paginate(currentPage - 1)} />
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (number) => (
                  <Pagination.Item
                    className="active"
                    key={number}
                    active={number === currentPage}
                    onClick={() => paginate(number)}
                  >
                    {number}
                  </Pagination.Item>
                )
              )}
              <Pagination.Next onClick={() => paginate(currentPage + 1)} />
              <Pagination.Last onClick={() => paginate(totalPages)} />
            </Pagination>
          </div>
        </div>
      </div>
      <AddBranchModal
        show={isModalOpen}
        handleClose={handleCloseModal}
        fetchBranches={fetchBranches}
      />
      {selectedBranch && (
        <Modal show={editModalOpen} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Branch</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="editBranchCode">
                <Form.Label className="pt-2">Branch Code</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedBranch.branchCode}
                  onChange={(e) =>
                    setSelectedBranch({
                      ...selectedBranch,
                      branchCode: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="editBranchName">
                <Form.Label className="pt-2">Branch Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedBranch.branchName}
                  onChange={(e) =>
                    setSelectedBranch({
                      ...selectedBranch,
                      branchName: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSaveChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default AdminBranchList;
