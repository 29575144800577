import React, { useState, useEffect } from "react";
import ApproveModal from "./ApproveModal";
import RetriggerModal from "./RetriggerModal";
import { BASE_URL } from "../env";
import axios from "axios";
import { Button } from "react-bootstrap";
import RejectModal from "./RejectModal";
import ApproveCreditManagerModal from "./ApproveCreditManagerModal";
import { TiTick } from "react-icons/ti";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

import DisburseModal from "./DisburseModal";
import ApproveSanctionCommitteeModal from "./ApproveSanctionCommitteeModal";
import ApproveAccountManagerModal from "./ApproveAccountManagerModal";
import UploadBranchManagerDocument from "./UploadBranchManagerDocument";
import DisburseBranchManagerModal from "./DisburseBranchManagerModal";

const ActionButtons = ({
  roleId,
  item,
  updateList,
  managerId,
  proposedLoanAmount,
  rateOfInterest,
  tenureInMonths,
}) => {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [message, setMessage] = useState("");
  const [comment, setComment] = useState("");
  const [retriggerToRole, setRetriggerToRole] = useState("");
  const [showRetriggerModal, setShowRetriggerModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveCreditManagerModal, setShowApproveCreditManagerModal] =
    useState(false);
  const [showDisburseModal, setShowDisburseModal] = useState(false);
  const [
    showApproveSanctionCommitteeModal,
    setShowApproveSanctionCommitteeModal,
  ] = useState(false);
  const [loanAmount, setLoanAmount] = useState("");
  const [showApproveAccountManagerModal, setShowApproveAccountManagerModal] =
    useState(false);
  const [transactionAccountNumber, setTransactionAccountNumber] = useState("");
  const [transactionRefNo, setTransactionRefNo] = useState("");
  const [transactionDate, setTransactionDate] = useState(new Date());
  const [transactionAmount, setTransactionAmount] = useState("");
  const [
    showUploadBranchManagerDocumentModal,
    setShowUploadBranchManagerDocumentModal,
  ] = useState(false);
  const [showDisburseBranchManagerModal, setShowDisburseBranchManagerModal] =
    useState(false);
  // const [processingCharge, setProcessingCharge] = useState(null);
  const [isProcessingChargePaid, setIsProcessingChargePaid] = useState(false);
  // const [gst, setGst] = useState(null);
  const [isGstPaid, setIsGstPaid] = useState(false);
  const [isSecurityDepositPaid, setIsSecurityDepositPaid] = useState(false);
  const [isLoanInsured, setIsLoanInsured] = useState(false);
  const [insuranceAmount, setInsuranceAmount] = useState(null);
  const [isInsuranceAmountPaid, setIsInsuranceAmountPaid] = useState(false);
  const [amountToRelease, setAmountToRelease] = useState(null);

  const processingCharge = Math.round(
    (item.sanctionedLoanAmountBySanctionCommittee * 2.5) / 100
  );
  const gst = Math.round((processingCharge * 18) / 100);
  const monthlyRate = rateOfInterest / 12 / 100;
  const securityDeposit = Math.round(
    (item.sanctionedLoanAmountBySanctionCommittee *
      monthlyRate *
      Math.pow(1 + monthlyRate, tenureInMonths)) /
      (Math.pow(1 + monthlyRate, tenureInMonths) - 1)
  );

  // console.log(
  //   "processingCharge: " +
  //     processingCharge +
  //     " gst: " +
  //     gst +
  //     " rateOfInterest: " +
  //     rateOfInterest +
  //     " monthlyRate: " +
  //     monthlyRate +
  //     " emi: " +
  //     emi
  // );

  const handleSubmit = async (id, msg, modalName) => {
    setMemberId(id);
    setMessage(msg);
    if (modalName === "submit") {
      setShowApproveModal(true);
    } else if (modalName === "retrigger") {
      setShowRetriggerModal(true);
    } else if (modalName === "reject") {
      setShowRejectModal(true);
    } else if (modalName === "submitCreditManager") {
      setShowApproveCreditManagerModal(true);
    } else if (modalName === "disburse") {
      setShowDisburseModal(true);
    } else if (modalName === "approveSanctionCommittee") {
      setShowApproveSanctionCommitteeModal(true);
    } else if (modalName === "submitAccountManager") {
      setShowApproveAccountManagerModal(true);
    } else if (modalName === "uploadBranchManagerDocument") {
      setShowUploadBranchManagerDocumentModal(true);
    } else if (modalName === "disburseBranchManager") {
      setShowDisburseBranchManagerModal(true);
    }
  };
  // const currentISTDateTime = new Date().toLocaleString("en-IN", {
  //   timeZone: "Asia/Kolkata",
  // });
  const currentISTDateTime = new Date().toISOString();

  const handleApproveConfirm = async () => {
    try {
      const payload = {};
      if (roleId === "Branch Manager") {
        payload.memberId = memberId;
        payload.branchManagerStatus = "submitted";
        payload.branchManagerStatusUpdatedAt = currentISTDateTime;
      }
      if (roleId === "Credit Officer") {
        payload.memberId = memberId;
        payload.creditOfficerStatus = "submitted";
        payload.creditOfficerStatusUpdatedAt = currentISTDateTime;
      }
      if (roleId === "MIS") {
        payload.memberId = memberId;
        payload.misStatus = "submitted";
        payload.misStatusUpdatedAt = currentISTDateTime;
      }

      await axios.put(`${BASE_URL}/api/manager/message`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowApproveModal(false);
    }
  };

  const handleCloseApprove = async () => {
    setShowApproveModal(false);
  };

  const handleRetriggerConfirm = async () => {
    try {
      const payload = {};
      const formattedMessage =
        "\n" +
        comment +
        "\n---By " +
        roleId +
        "\n-- " +
        currentISTDateTime +
        "\n******** ";
      payload.memberId = memberId;
      if (retriggerToRole === "Customer Relationship Officer") {
        payload.fieldManagerStatus =
          roleId === "Accounts Manager"
            ? "retriggerToEditBankDetails"
            : "retrigger";
        payload.fieldManagerMessage = formattedMessage;
        payload.fieldManagerStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "Branch Manager") {
        payload.branchManagerStatus = "retrigger";
        payload.branchManagerMessage = formattedMessage;
        payload.branchManagerStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "Credit Officer") {
        payload.creditOfficerStatus = "retrigger";
        payload.creditOfficerMessage = formattedMessage;
        payload.creditOfficerStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "MIS") {
        payload.misStatus = "retrigger";
        payload.misMessage = formattedMessage;
        payload.misStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "Credit Manager") {
        payload.creditManagerStatus = "retrigger";
        payload.creditManagerMessage = formattedMessage;
        payload.creditManagerStatusUpdatedAt = currentISTDateTime;
      }
      if (retriggerToRole === "Sanction Committee") {
        payload.sanctionCommitteeStatus = "retrigger";
        payload.sanctionCommitteeMessage = formattedMessage;
        payload.sanctionCommitteeStatusUpdatedAt = currentISTDateTime;
      }

      await axios.put(`${BASE_URL}/api/manager/message`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowRetriggerModal(false);
    }
  };

  const handleCloseRetrigger = async () => {
    setShowRetriggerModal(false);
  };

  const handleRejectConfirm = async () => {
    try {
      const payload = {};
      const formattedMessage =
        "\n" +
        comment +
        "\n---By " +
        roleId +
        "\n-- " +
        currentISTDateTime +
        "\n******** ";
      if (roleId === "Sanction Committee") {
        payload.memberId = memberId;
        payload.sanctionCommitteeStatus = "rejected";
        payload.sanctionCommitteeMessage = formattedMessage;
        payload.sanctionCommitteeStatusUpdatedAt = currentISTDateTime;
      }

      await axios.put(`${BASE_URL}/api/manager/message`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowRejectModal(false);
    }
  };

  const handleCloseReject = async () => {
    setShowRejectModal(false);
  };

  const handleApproveConfirmCreditManager = async () => {
    updateList();
    setShowApproveCreditManagerModal(false);
  };

  const handleCloseApproveCreditManager = async () => {
    setShowApproveCreditManagerModal(false);
  };

  const handleDisburseConfirm = async () => {
    try {
      const payload = {};
      if (roleId === "MIS") {
        payload.memberId = memberId;
        payload.misStatus = "disbursed";
        payload.misStatusUpdatedAt = currentISTDateTime;
      }

      await axios.put(`${BASE_URL}/api/manager/message`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowDisburseModal(false);
    }
  };

  const handleCloseDisburse = async () => {
    setShowDisburseModal(false);
  };

  const handleDisburseConfirmBranchMAnager = async () => {
    try {
      const payload = {};
      if (roleId === "Branch Manager") {
        payload.memberId = memberId;
        payload.branchManagerStatus = "disbursed";
        payload.branchManagerStatusUpdatedAt = currentISTDateTime;
        payload.processingCharge = processingCharge;
        payload.isProcessingChargePaid = isProcessingChargePaid;
        payload.gst = gst;
        payload.isGstPaid = isGstPaid;
        payload.securityDeposit = securityDeposit;
        payload.isSecurityDepositPaid = isSecurityDepositPaid;
        payload.isLoanInsured = isLoanInsured;
        payload.insuranceAmount = insuranceAmount;
        payload.isInsuranceAmountPaid = isInsuranceAmountPaid;
        payload.amountToRelease = amountToRelease;
      }

      await axios.put(`${BASE_URL}/api/manager/message`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowDisburseBranchManagerModal(false);
    }
  };

  const handleCloseDisburseBranchMAnager = async () => {
    setShowDisburseBranchManagerModal(false);
  };

  const handleApproveConfirmSanctionCommittee = async () => {
    try {
      const payload = {};

      if (roleId === "Sanction Committee") {
        payload.memberId = memberId;
        payload.sanctionCommitteeStatus = "approved";
        payload.sanctionCommitteeStatusUpdatedAt = currentISTDateTime;
        payload.sanctionedLoanAmountBySanctionCommittee = loanAmount;
      }

      await axios.put(`${BASE_URL}/api/manager/message`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowApproveSanctionCommitteeModal(false);
    }
  };

  const handleCloseApproveSanctionCommittee = async () => {
    setShowApproveSanctionCommitteeModal(false);
  };

  const handleApproveConfirmAccountManager = async () => {
    try {
      const payload = {};

      if (roleId === "Accounts Manager") {
        payload.memberId = memberId;
        payload.accountManagerStatus = "payment credited";
        payload.accountManagerStatusUpdatedAt = currentISTDateTime;
        payload.transactionAccountNumber = transactionAccountNumber;
        payload.transactionRefNo = transactionRefNo;
        payload.transactionAmount = transactionAmount;
        payload.transactionDate = transactionDate;
      }

      await axios.put(`${BASE_URL}/api/manager/message`, {
        ...payload,
      });
      updateList();
    } catch (error) {
      console.log("Error fetching list:", error);
    } finally {
      setShowApproveAccountManagerModal(false);
    }
  };

  const handleCloseApproveAccountManager = async () => {
    setShowApproveAccountManagerModal(false);
  };

  const handleApproveConfirmUploadBranchManagerDocument = async () => {
    updateList();
    setShowUploadBranchManagerDocumentModal(false);
  };

  const handleCloseUploadBranchManagerDocument = async () => {
    setShowUploadBranchManagerDocumentModal(false);
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {((roleId === "Branch Manager" &&
        item.fieldManagerStatus === "submitted" &&
        ["pending", "retrigger"].includes(item.branchManagerStatus) &&
        item.creditOfficerStatus !== "submitted") ||
        (roleId === "Credit Officer" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "submitted" &&
          ["pending", "retrigger"].includes(item.creditOfficerStatus)) ||
        (roleId === "MIS" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "submitted" &&
          item.creditOfficerStatus === "submitted" &&
          ["pending", "retrigger"].includes(item.misStatus))) && (
        <div className="mb-1">
          <Button
            variant="success"
            onClick={() =>
              handleSubmit(
                item.id,
                "Are you sure you want to Submit?",
                "submit"
              )
            }
          >
            <TiTick size={20} /> Submit
          </Button>
        </div>
      )}
      {roleId === "Credit Manager" &&
        item.fieldManagerStatus === "submitted" &&
        item.branchManagerStatus === "submitted" &&
        item.creditOfficerStatus === "submitted" &&
        item.misStatus === "submitted" &&
        ["pending", "retrigger"].includes(item.creditManagerStatus) && (
          <div className="mb-2">
            <Button
              variant="success"
              onClick={() =>
                handleSubmit(
                  item.id,
                  "Are you sure you want to Submit?",
                  "submitCreditManager"
                )
              }
            >
              <TiTick size={20} /> Submit
            </Button>
          </div>
        )}
      {((roleId === "Branch Manager" &&
        item.fieldManagerStatus === "submitted" &&
        item.branchManagerStatus === "pending") ||
        (roleId === "Branch Manager" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "submitted" &&
          item.creditOfficerStatus === "submitted" &&
          item.misStatus === "submitted" &&
          item.creditManagerStatus === "submitted" &&
          item.sanctionCommitteeStatus === "approved" &&
          item.accountManagerStatus === "pending") ||
        (roleId === "Credit Officer" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "submitted" &&
          item.creditOfficerStatus === "pending") ||
        (roleId === "MIS" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "submitted" &&
          item.creditOfficerStatus === "submitted" &&
          item.misStatus === "pending") ||
        (roleId === "MIS" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "disbursed" &&
          item.creditOfficerStatus === "submitted" &&
          item.misStatus === "submitted" &&
          item.creditManagerStatus === "submitted" &&
          item.sanctionCommitteeStatus === "approved" &&
          item.accountManagerStatus === "pending") ||
        (roleId === "Credit Manager" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "submitted" &&
          item.creditOfficerStatus === "submitted" &&
          item.misStatus === "submitted" &&
          item.creditManagerStatus === "pending") ||
        (roleId === "Sanction Committee" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "submitted" &&
          item.creditOfficerStatus === "submitted" &&
          item.misStatus === "submitted" &&
          item.creditManagerStatus === "submitted" &&
          item.sanctionCommitteeStatus === "pending") ||
        (roleId === "Accounts Manager" &&
          item.fieldManagerStatus === "submitted" &&
          item.branchManagerStatus === "disbursed" &&
          item.creditOfficerStatus === "submitted" &&
          item.misStatus === "disbursed" &&
          item.creditManagerStatus === "submitted" &&
          item.sanctionCommitteeStatus === "approved" &&
          item.accountManagerStatus === "pending")) && (
        <div className="mb-2">
          <Button
            variant="warning"
            onClick={() =>
              handleSubmit(
                item.id,
                "Are you sure you want to Retrigger?",
                "retrigger"
              )
            }
          >
            <FaArrowRotateLeft size={17} />
          </Button>
        </div>
      )}
      {roleId === "Sanction Committee" &&
        item.fieldManagerStatus === "submitted" &&
        item.branchManagerStatus === "submitted" &&
        item.creditOfficerStatus === "submitted" &&
        item.misStatus === "submitted" &&
        item.creditManagerStatus === "submitted" &&
        ["pending", "retrigger"].includes(item.sanctionCommitteeStatus) && (
          <div className="mb-2">
            <Button
              variant="danger"
              onClick={() =>
                handleSubmit(
                  item.id,
                  "Are you sure you want to Reject?",
                  "reject"
                )
              }
            >
              <RxCross2 size={20} />
            </Button>
          </div>
        )}
      {roleId === "Branch Manager" &&
        item.fieldManagerStatus === "submitted" &&
        ["submitted", "retrigger"].includes(item.branchManagerStatus) &&
        item.creditOfficerStatus === "submitted" &&
        item.misStatus === "submitted" &&
        item.creditManagerStatus === "submitted" &&
        item.sanctionCommitteeStatus === "approved" &&
        ["pending"].includes(item.accountManagerStatus) && (
          <div className="mb-1">
            <Button
              onClick={() =>
                handleSubmit(
                  item.id,
                  "Are you sure you want to Disburse?",
                  "disburseBranchManager"
                )
              }
            >
              Disburse
            </Button>
          </div>
        )}
      {roleId === "MIS" &&
        item.fieldManagerStatus === "submitted" &&
        item.branchManagerStatus === "disbursed" &&
        item.creditOfficerStatus === "submitted" &&
        item.misStatus === "submitted" &&
        item.creditManagerStatus === "submitted" &&
        item.sanctionCommitteeStatus === "approved" &&
        ["pending"].includes(item.accountManagerStatus) && (
          <div className="mb-1">
            <Button
              onClick={() =>
                handleSubmit(
                  item.id,
                  "Are you sure you want to Disburse?",
                  "disburse"
                )
              }
            >
              Disburse
            </Button>
          </div>
        )}
      {roleId === "Sanction Committee" &&
        item.fieldManagerStatus === "submitted" &&
        item.branchManagerStatus === "submitted" &&
        item.creditOfficerStatus === "submitted" &&
        item.misStatus === "submitted" &&
        item.creditManagerStatus === "submitted" &&
        ["pending", "retrigger"].includes(item.sanctionCommitteeStatus) && (
          <div className="mb-2">
            <Button
              variant="danger"
              onClick={() =>
                handleSubmit(
                  item.id,
                  "Are you sure you want to Approve?",
                  "approveSanctionCommittee"
                )
              }
            >
              <TiTick size={20} />
            </Button>
          </div>
        )}
      {roleId === "Accounts Manager" &&
        item.fieldManagerStatus === "submitted" &&
        item.branchManagerStatus === "disbursed" &&
        item.creditOfficerStatus === "submitted" &&
        item.misStatus === "disbursed" &&
        item.creditManagerStatus === "submitted" &&
        item.sanctionCommitteeStatus === "approved" &&
        ["pending"].includes(item.accountManagerStatus) && (
          <div className="mb-1">
            <Button
              onClick={() =>
                handleSubmit(
                  item.id,
                  "Are you sure you want to Submit?",
                  "submitAccountManager"
                )
              }
            >
              Submit
            </Button>
          </div>
        )}
      {roleId === "Branch Manager" &&
        item.fieldManagerStatus === "submitted" &&
        ["submitted", "retrigger"].includes(item.branchManagerStatus) &&
        item.creditOfficerStatus === "submitted" &&
        item.misStatus === "submitted" &&
        item.creditManagerStatus === "submitted" &&
        item.sanctionCommitteeStatus === "approved" &&
        ["pending"].includes(item.accountManagerStatus) && (
          <div className="mb-1">
            <Button
              onClick={() =>
                handleSubmit(
                  item.id,
                  "Are you sure you want to Upload?",
                  "uploadBranchManagerDocument"
                )
              }
            >
              Upload Document
            </Button>
          </div>
        )}

      {showApproveModal && (
        <ApproveModal
          show={showApproveModal}
          handleClose={handleCloseApprove}
          handleConfirm={handleApproveConfirm}
          message={message}
        />
      )}
      {showRetriggerModal && (
        <RetriggerModal
          show={showRetriggerModal}
          handleClose={handleCloseRetrigger}
          handleConfirm={handleRetriggerConfirm}
          message={message}
          comment={comment}
          setComment={setComment}
          retriggerToRole={retriggerToRole}
          setRetriggerToRole={setRetriggerToRole}
          roleId={roleId}
          item={item}
        />
      )}
      {showRejectModal && (
        <RejectModal
          show={showRejectModal}
          handleClose={handleCloseReject}
          handleConfirm={handleRejectConfirm}
          message={message}
          comment={comment}
          setComment={setComment}
        />
      )}
      {showApproveCreditManagerModal && (
        <ApproveCreditManagerModal
          show={showApproveCreditManagerModal}
          handleClose={handleCloseApproveCreditManager}
          handleConfirm={handleApproveConfirmCreditManager}
          memberId={memberId}
          creditManagerId={managerId}
          proposedLoanAmount={proposedLoanAmount}
        />
      )}
      {showDisburseModal && (
        <DisburseModal
          show={showDisburseModal}
          handleClose={handleCloseDisburse}
          handleConfirm={handleDisburseConfirm}
          message={message}
        />
      )}
      {showApproveSanctionCommitteeModal && (
        <ApproveSanctionCommitteeModal
          show={showApproveSanctionCommitteeModal}
          handleClose={handleCloseApproveSanctionCommittee}
          handleConfirm={handleApproveConfirmSanctionCommittee}
          message={message}
          loanAmount={loanAmount}
          setLoanAmount={setLoanAmount}
          proposedLoanAmount={proposedLoanAmount}
        />
      )}
      {showApproveAccountManagerModal && (
        <ApproveAccountManagerModal
          show={showApproveAccountManagerModal}
          handleClose={handleCloseApproveAccountManager}
          handleConfirm={handleApproveConfirmAccountManager}
          message={message}
          transactionAccountNumber={transactionAccountNumber}
          setTransactionAccountNumber={setTransactionAccountNumber}
          transactionRefNo={transactionRefNo}
          setTransactionRefNo={setTransactionRefNo}
          transactionDate={transactionDate}
          setTransactionDate={setTransactionDate}
          transactionAmount={transactionAmount}
          setTransactionAmount={setTransactionAmount}
          proposedLoanAmount={proposedLoanAmount}
          sanctionedLoanAmount={item.sanctionedLoanAmountBySanctionCommittee}
          processingCharge={item.processingCharge}
          isProcessingChargePaid={item.isProcessingChargePaid}
          gst={item.gst}
          isGstPaid={item.isGstPaid}
          securityDeposit={item.securityDeposit}
          isSecurityDepositPaid={item.isSecurityDepositPaid}
          isLoanInsured={item.isLoanInsured}
          insuranceAmount={item.insuranceAmount}
          isInsuranceAmountPaid={item.isInsuranceAmountPaid}
          amountToRelease={item.amountToRelease}
        />
      )}
      {showUploadBranchManagerDocumentModal && (
        <UploadBranchManagerDocument
          show={showUploadBranchManagerDocumentModal}
          handleClose={handleCloseUploadBranchManagerDocument}
          handleConfirm={handleApproveConfirmUploadBranchManagerDocument}
          message={message}
          memberId={memberId}
          branchManagerId={managerId}
        />
      )}
      {showDisburseBranchManagerModal && (
        <DisburseBranchManagerModal
          show={showDisburseBranchManagerModal}
          handleClose={handleCloseDisburseBranchMAnager}
          handleConfirm={handleDisburseConfirmBranchMAnager}
          message={message}
          proposedLoanAmount={proposedLoanAmount}
          sanctionedLoanAmount={item.sanctionedLoanAmountBySanctionCommittee}
          processingCharge={processingCharge}
          isProcessingChargePaid={isProcessingChargePaid}
          setIsProcessingChargePaid={setIsProcessingChargePaid}
          gst={gst}
          isGstPaid={isGstPaid}
          setIsGstPaid={setIsGstPaid}
          securityDeposit={securityDeposit}
          isSecurityDepositPaid={isSecurityDepositPaid}
          setIsSecurityDepositPaid={setIsSecurityDepositPaid}
          isLoanInsured={isLoanInsured}
          setIsLoanInsured={setIsLoanInsured}
          insuranceAmount={insuranceAmount}
          setInsuranceAmount={setInsuranceAmount}
          isInsuranceAmountPaid={isInsuranceAmountPaid}
          setIsInsuranceAmountPaid={setIsInsuranceAmountPaid}
          amountToRelease={amountToRelease}
          setAmountToRelease={setAmountToRelease}
        />
      )}
    </div>
  );
};

export default ActionButtons;
