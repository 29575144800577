import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../env";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddBranchModal = ({ show, handleClose,fetchBranches}) => {
  const [branchCode, setBranchCode] = useState("");
  const [branchName, setBranchName] = useState("");

  const handleAddBranch = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/create/branch`, { branchName, branchCode });
    
      setBranchName("");
      setBranchCode("");
      handleClose();
      fetchBranches();
      toast.success("Branch added Sucessfully")
    } catch (error) {
     
       handleClose();
      setBranchName("");
      setBranchCode("");
      toast.error(error.response.data.error)
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Branch</p>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Group>
              <p>Branch Code</p>
              <Form.Control
                type="text"
                placeholder="Enter Branch Code"
                value={branchCode}
                onChange={(e) => setBranchCode(e.target.value)}
              />
            </Form.Group>
            <p className="mt-2">Branch Name</p>
            <Form.Control
              type="text"
              placeholder="Enter Branch Name"
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button className="bg-danger btn" style={{ color: 'white' }} onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-addCredential" onClick={handleAddBranch}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
      {/* Render ToastContainer */}
      <ToastContainer position="top-center" />
    </>
  );
};

export default AddBranchModal;