import React, { useState } from 'react';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

function MyProgressBar() {
  const [currentStep, setCurrentStep] = useState(0); // Step index (0-7 for 8 steps)
  const totalSteps = 8;

  // Calculate the percent based on current step
  const progressPercentage = (currentStep / (totalSteps - 1)) * 100;

  // Handle the "Next" button click
  const handleNextClick = () => {
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Handle the "Previous" button click
  const handlePrevClick = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div>
      {/* Progress Bar */}
      <ProgressBar
        percent={progressPercentage}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
      >
        {/* Map over the steps */}
        {[...Array(totalSteps)].map((_, index) => (
          <Step key={index}>
            {({ accomplished }) => (
              <div
                className={`step-circle ${accomplished ? "completed" : ""}`}
              />
            )}
          </Step>
        ))}
      </ProgressBar>

      {/* Navigation Buttons */}
      <div style={{ marginTop: "20px" }}>
        <button className='progressbarbtn1' onClick={handlePrevClick} disabled={currentStep === 0}>
          Previous
        </button>
        <button className='progressbarbtn2' onClick={handleNextClick} disabled={currentStep === totalSteps - 1}>
          Next
        </button>
      </div>
    </div>
  );
}

export default MyProgressBar;
