import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";

const ApproveAccountManagerModal = ({
  show,
  handleClose,
  handleConfirm,
  message,
  transactionAccountNumber,
  setTransactionAccountNumber,
  transactionRefNo,
  setTransactionRefNo,
  transactionDate,
  setTransactionDate,
  transactionAmount,
  setTransactionAmount,
  proposedLoanAmount,
  sanctionedLoanAmount,
  processingCharge,
  isProcessingChargePaid,
  gst,
  isGstPaid,
  securityDeposit,
  isSecurityDepositPaid,
  isLoanInsured,
  insuranceAmount,
  isInsuranceAmountPaid,
  amountToRelease,
}) => {
  const [validationError, setValidationError] = useState("");

  const handleClick = () => {
    if (
      !transactionAccountNumber ||
      !transactionRefNo ||
      !transactionDate ||
      !transactionAmount
    ) {
      setValidationError("Please fill all fields");
    } else {
      setValidationError("");
      handleConfirm();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>{message}</h6>
        <div className="my-3">
          <p>Proposed Loan Amount : Rs.{proposedLoanAmount}</p>
          <p>Sanctioned Loan Amount : Rs.{sanctionedLoanAmount}</p>
          <p>Sanctioned Loan Amount : Rs.{processingCharge}</p>
          <p>
            Collected Processing Charge? :{" "}
            {isProcessingChargePaid ? "Yes" : "No"}
          </p>
          <p>GST Amount : Rs.{gst}</p>
          <p>Collected GST? : {isGstPaid ? "Yes" : "No"}</p>
          <p>Security Deposit : Rs.{securityDeposit}</p>
          <p>
            Collected Security Deposit? : {isSecurityDepositPaid ? "Yes" : "No"}
          </p>
          <p>Loan Insured? : {isLoanInsured ? "Yes" : "No"}</p>
          {isLoanInsured && (
            <>
              <p>Insurance Amount : Rs.{insuranceAmount}</p>
              <p>
                Collected Insurance Amount? :{" "}
                {isInsuranceAmountPaid ? "Yes" : "No"}
              </p>
            </>
          )}
          <p>Loan Amount to Release : Rs.{amountToRelease}</p>
          <Form.Group>
            <Form.Label>Bank Account Number</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Bank Account Number"
              value={transactionAccountNumber}
              onChange={(event) => {
                setTransactionAccountNumber(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Transaction Ref No</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Transaction Ref No"
              value={transactionRefNo}
              onChange={(event) => {
                setTransactionRefNo(event.target.value);
              }}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Transaction Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Transaction Amount"
              value={transactionAmount}
              onChange={(event) => {
                setTransactionAmount(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => e.preventDefault()}
              onFocus={(e) => {
                e.target.addEventListener("wheel", (e) => e.preventDefault(), {
                  passive: false,
                });
              }}
              onBlur={(e) => {
                e.target.removeEventListener("wheel", (e) =>
                  e.preventDefault()
                );
              }}
              step="1"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Transaction Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Enter Date"
              value={transactionDate}
              onChange={(event) => setTransactionDate(event.target.value)}
            />
          </Form.Group>
          <p className="text-danger">{validationError}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApproveAccountManagerModal;
