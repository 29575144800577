import React, { useState } from "react";
import { useAdminAuth } from "../components/AdminAuthProvider";
import {
  useNavigate,
  // , useLocation
} from "react-router-dom";
import { FaUserLock } from "react-icons/fa";
import { BiSolidUserDetail } from "react-icons/bi";
// import { FaUserCheck } from "react-icons/fa6";
import { BiSolidDashboard } from "react-icons/bi";
import {
  RiLogoutCircleRLine,
  RiLoginCircleLine,
  // RiCalendarScheduleFill,
} from "react-icons/ri";
// import { MdDangerous } from "react-icons/md";
import { PiGitBranchBold } from "react-icons/pi";
// import { GiTakeMyMoney } from "react-icons/gi";
import {
  // MdEditCalendar,
  MdArrowDropDown,
} from "react-icons/md";
// import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";

const Sidebar = ({ openSidebarToggle, OpenSidebar }) => {
  const { adminUser, adminLogout } = useAdminAuth();
  const navigate = useNavigate();
  // const location = useLocation();

  const [isMasterDropdownOpen, setMasterDropdownOpen] = useState(false);
  const [isActive, setIsActive] = useState("Dashboard");

  const isSuperAdmin = adminUser && adminUser.userType === "superadmin";
  const isBranchManager = adminUser && adminUser.userType === "Branch Manager";
  // const isCreditOfficer = adminUser && adminUser.userType === "Credit Officer";
  const isMis = adminUser && adminUser.userType === "MIS";
  // const isCreditManager = adminUser && adminUser.userType === "Credit Manager";
  // const isSanctionCommittee =
  //   adminUser && adminUser.userType === "Sanction Committee";
  const isAccountsManager =
    adminUser && adminUser.userType === "Accounts Manager";

  const handleLoginCredentialsClick = () => {
    navigate("/logincredential");
  };

  const handleLogoutClick = () => {
    adminLogout();
    navigate("/");
  };

  const handleDashboard = () => {
    navigate("/Dashboard");
  };

  const toggleMasterDropdown = () => {
    setMasterDropdownOpen(!isMasterDropdownOpen);
  };

  // const isActive = (path) => location.pathname === path;

  return (
    <div>
      <aside
        id="sidebar"
        className={
          openSidebarToggle ? "sidebar-responsive" : "sidebar-responsive-set"
        }
      >
        <IoClose
          className="icon closeIconSideNav"
          onClick={OpenSidebar}
          size={24}
        />
        <div className="sidebar-title text-center" onClick={handleDashboard}>
          <div className="profile-sidebar">
            <img
              src={require("../assets/profile.png")}
              className="img-radius"
              alt="User-Profile-pic"
            />
          </div>
          <div className="sidebar-brand">
            <span>{adminUser.user.username}</span>
          </div>
        </div>

        <ul className="sidebar-list">
          <li
            className={`sidebar-list-item ${
              isActive === "Dashboard" ? "active" : ""
            }`}
            onClick={() => {
              setIsActive("Dashboard");
              navigate("/Dashboard");
            }}
          >
            {/* <p>Dashboard</p> */}
            <span className="gap-2">
              <BiSolidDashboard className="icon" /> Dashboard
            </span>
          </li>
          {isSuperAdmin && (
            <>
              <li className=" sidebar-list-item">
                {/* <p>Master</p> */}

                <div
                  className=" sidebarhover"
                  style={{
                    borderBottom: "0px",
                    padding: "10px",
                    fontSize: "14px",
                  }}
                >
                  <div
                    onClick={toggleMasterDropdown}
                    style={{
                      borderBottom: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span className="gap-2">
                      <FaUserLock className="icon" /> Master
                    </span>

                    <span>
                      <MdArrowDropDown
                        className="icon"
                        style={{ display: "flex", alignSelf: "flex-end" }}
                      />
                    </span>
                  </div>
                </div>
              </li>
              {isMasterDropdownOpen && (
                <ul
                  style={{ listStyle: "none" }}
                  className="nested-list-master"
                >
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Branch" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Branch");
                      navigate("/branch");
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <PiGitBranchBold className="icon" /> Branch
                    </span>
                  </li>
                  <li
                    className={`nested-list-master-item ${
                      isActive === "Login Credentials" ? "active" : ""
                    } p-2`}
                    onClick={() => {
                      setIsActive("Login Credentials");
                      handleLoginCredentialsClick();
                    }}
                    style={{ borderRadius: 5 }}
                  >
                    <span className="">
                      <RiLoginCircleLine className="icon" /> Login Credentials
                    </span>
                  </li>
                </ul>
              )}
              <li
                className={`sidebar-list-item ${
                  isActive === "Member Details" ? "active" : ""
                }`}
                onClick={() => {
                  setIsActive("Member Details");
                  navigate("/memberdetails", {
                    state: {
                      manager: "",
                      filterStatus: "",
                      isActive: "Member Details",
                    },
                  });
                }}
              >
                <span className="gap-2">
                  <BiSolidUserDetail className="icon" /> Member Details
                </span>
              </li>
            </>
          )}
          <li
            className={`sidebar-list-item ${
              isActive === "approved" ? "active" : ""
            }`}
            onClick={() => {
              setIsActive("Approved");
              navigate("/memberdetails", {
                state: {
                  filterStatus: "approved",
                  isActive: "Approved",
                },
              });
            }}
          >
            <span className="gap-2">
              <BiSolidUserDetail className="icon" /> Approved
            </span>
          </li>
          {!isSuperAdmin && (
            <li
              className={`sidebar-list-item ${
                isActive === "Retrigger" ? "active" : ""
              }`}
              onClick={() => {
                setIsActive("Retrigger");
                navigate("/memberdetails", {
                  state: {
                    filterStatus: "retrigger",
                    isActive: "Retrigger",
                  },
                });
              }}
            >
              <span className="gap-2">
                <BiSolidUserDetail className="icon" /> Retrigger
              </span>
            </li>
          )}
          {!isSuperAdmin && (isBranchManager || isMis || isAccountsManager) && (
            <li
              className={`sidebar-list-item ${
                isActive === "Disbursed" ? "active" : ""
              }`}
              onClick={() => {
                setIsActive("Disbursed");
                navigate("/memberdetails", {
                  state: {
                    filterStatus: "disbursed",
                    isActive: "Disbursed",
                  },
                });
              }}
            >
              <span className="gap-2">
                <BiSolidUserDetail className="icon" /> Disbursed
              </span>
            </li>
          )}
          <li
            className={`sidebar-list-item ${
              isActive === "Pending" ? "active" : ""
            }`}
            onClick={() => {
              setIsActive("Pending");
              navigate("/memberdetails", {
                state: {
                  filterStatus: "pending",
                  isActive: "Pending",
                },
              });
            }}
          >
            <span className="gap-2">
              <BiSolidUserDetail className="icon" /> Pending
            </span>
          </li>
          <li
            className={`sidebar-list-item ${
              isActive === "Rejected" ? "active" : ""
            }`}
            onClick={() => {
              setIsActive("Rejected");
              navigate("/memberdetails", {
                state: {
                  filterStatus: "rejected",
                  isActive: "Rejected",
                },
              });
            }}
          >
            <span className="gap-2">
              <BiSolidUserDetail className="icon" /> Rejected
            </span>
          </li>

          <li className="sidebar-list-item" onClick={handleLogoutClick}>
            <span className="gap-2">
              <RiLogoutCircleRLine className="icon" /> Logout
            </span>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
