import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginForm from './screens/LoginForm';
import Dashboard from './screens/Dashboard';
import LoginCredentials from './screens/LoginCredentials';
import { useAdminAuth } from './components/AdminAuthProvider';
import MemberDetails from "./screens/MemberDetails";
import ApprovedMemberDetails from './screens/ApprovedMemberDetails';
import RejectedMemberDetails from './screens/RejectedMemberDetails';
import MemberDetailPrint from './screens/MemberDetailPrint';
import AdminBranchList from './components/AdminBranchList';
import LoanFrequencyList from './screens/LoanFrequencyList';
import LoanTenureList from './screens/LoanTenureList';
import FundingAgencyList from './screens/FundingAgencyList';
import DisbursedMemberDetail from './screens/DisbursedMemberDetail';
import MergedLoanDetails from './screens/MergedLoanDetails';
import LoanScheduleList from './screens/LoanScheduleList';
import BusinessList from './components/BusinessList';

export default function App() {
  const { adminUser } = useAdminAuth();

  const isSuperAdmin = adminUser && adminUser.userType === "superadmin";

  return (
    <Routes>
      <Route path="/" element={<LoginForm />} />
      {adminUser && (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path ="/memberdetails" element={<MemberDetails/>}/>
          <Route path ="/approved/members" element={<ApprovedMemberDetails/>}/>
          <Route path ="/rejected/members" element={<RejectedMemberDetails/>}/>
          <Route path ="/memberdetail/print" element={<MemberDetailPrint/>}/>
        

          {isSuperAdmin && (
           <>
            <Route path="/logincredential" element={<LoginCredentials />} />
            <Route path="/branch" element={<AdminBranchList />} />
            <Route path="/loanfrequency/list" element={<LoanFrequencyList />} />
            <Route path="/loantenure/list" element={<LoanTenureList />} />
            <Route path="/funding/agency/list" element={<FundingAgencyList />} />
            <Route path ="/disbursed/member" element={<DisbursedMemberDetail/>}/>
            <Route path ="/merged/loan/details" element={<MergedLoanDetails/>}/>
            <Route path ="/loan/schedule/list" element={<LoanScheduleList/>}/>
            <Route path ="/business/list" element={<BusinessList/>}/>
           </>
          )}
        </>
        
      )}
      
    </Routes>
  );
}
