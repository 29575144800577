import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../env";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { MdModeEdit } from "react-icons/md";

const CreditOfficerList = ({ searchQuery, refreshList }) => {
  const [creditOfficers, setCreditOfficers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedUsername, setEditedUsername] = useState("");
  const [editedPassword, setEditedPassword] = useState("");

  const fetchCreditOfficers = async () => {
    try {
       const response = await axios.get(`${BASE_URL}/api/credit/officers`);
     setCreditOfficers(response.data.branchManager);
    } catch (error) {
      console.error("Error fetching credit managers:", error);
    }
  };

  useEffect(() => {
    fetchCreditOfficers();
  }, [refreshList]);

  const handleEdit = (manager) => {
    setSelectedManager(manager);
    setEditedUsername(manager.username);
    setEditedPassword(manager.password);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleSaveChanges = async () => {
    try {
      await axios.put(`${BASE_URL}/api/credit/officers/${selectedManager.id}`, {
        username: editedUsername,
        password: editedPassword,
      });

      fetchCreditOfficers();
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating manager details:", error);
      alert("Error updating manager details")
      setShowEditModal(false);
    }
  };

  const filteredManagers = creditOfficers.filter((manager) =>
    manager.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
    manager.password.toLowerCase().includes(searchQuery.toLowerCase()) ||
    manager.branch_names.toLowerCase().includes(searchQuery.toLowerCase())
  );


  return (
    <div className="mt-4 text-center">
      <h5 className="pb-3 TableHeaderText">Credit Officer List </h5>
      <div className="table-responsive">
        <table className="mx-auto table table-striped table-list">
          <thead>
            <tr>
              <th>Username</th>
              <th>Password</th>
              <th>Branch Names</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredManagers.length > 0 ? (
              filteredManagers.map((manager) => (
                <tr key={manager.id}>
                  <td>{manager.username}</td>
                  <td>{manager.password}</td>
                  <td>{manager.branch_names.split(",").join(", ")}</td>
                  <td>
                    <button
                      className="btn btn-edit"
                      title="Edit"
                      onClick={() => handleEdit(manager)}
                    >
                      <MdModeEdit />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No credit officer found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal show={showEditModal} onHide={handleCloseEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editedUsername">
              <Form.Label className="pt-2">Username</Form.Label>
              <Form.Control
                type="text"
                value={editedUsername}
                onChange={(e) => setEditedUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="editedPassword">
              <Form.Label className="pt-4">Password</Form.Label>
              <Form.Control
                type="text"
                value={editedPassword}
                onChange={(e) => setEditedPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="bg-danger btn" style={{ color: 'white' }} onClick={handleCloseEditModal}>
            Close
          </button>
          <button className="btn btn-addCredential" onClick={handleSaveChanges}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreditOfficerList;
