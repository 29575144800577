import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import AddBranchModal from "./AddBranchModal";
import { BASE_URL } from "../env";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddLoginCredential = ({ onCredentialAdded }) => {
  const [showModal, setShowModal] = useState(false);
  const [showBranchAddModal, setShowBranchAddModal] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const fetchBranches = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/getall/branches`);

      setBranches(response.data);
    } catch (error) {
      console.error("Error fetching branches:", error);
    }
  };

  useEffect(() => {
    fetchBranches();
  }, []);

  const handleShowNestedModal = () => setShowBranchAddModal(true);
  const handleCloseNestedModal = () => setShowBranchAddModal(false);

  const handleChangeBranch = (selectedOption) => {
    setSelectedBranch(selectedOption);
  };

  const handleChangeRole = (selectedOption) => {
    setSelectedRole(selectedOption);
    if (selectedOption.value === "Customer Relationship Officer") {
      setSelectedBranch(null);
    }
  };

  const handleAddLogin = async () => {
    if (!username || !password || !selectedRole || !selectedBranch) {
      alert("Please select all fields");
      return;
    }
    try {
      await axios.post(`${BASE_URL}/api/add/credential`, {
        username: username,
        password: password,
        roleId: selectedRole.value,
        branchId: selectedBranch
          ? Array.isArray(selectedBranch)
            ? selectedBranch.map((branch) => branch.value).join(",")
            : selectedBranch.value
          : null,
      });

      onCredentialAdded();
      setUsername("");
      setPassword("");
      setSelectedBranch(null);
      setSelectedRole(null);

      setShowModal(false);
      // toast.success(response.data.message)
      // setTimeout( window.location.reload(),5)
    } catch (error) {
      // toast.error(error.response.data.message);
      alert("Error in Adding Login Credentials");

      setUsername("");
      setPassword("");
      setSelectedBranch(null);
      setSelectedRole(null);
    }
  };

  return (
    <div className="">
      <button className="btn btn-addCredential" onClick={toggleModal}>
        Add Credential
      </button>

      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Login Credential</p>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex jusify-content-center align-items-center">
            <Select
              value={selectedRole}
              onChange={handleChangeRole}
              options={[
                { value: "Accounts Manager", label: "Accounts Manager" },
                { value: "Sanction Committee", label: "Sanction Committee" },
                { value: "Credit Manager", label: "Credit Manager" },
                { value: "MIS", label: "MIS" },
                { value: "Credit Officer", label: "Credit Officer" },
                { value: "Branch Manager", label: "Branch Manager" },
                {
                  value: "Customer Relationship Officer",
                  label: "Customer Relationship Officer",
                },
              ]}
              isClearable
              placeholder="---Select Role---"
              className="w-100"
            />
          </div>
          <div className="d-flex jusify-content-center align-items-center ">
            <Select
              value={selectedBranch}
              onChange={handleChangeBranch}
              options={branches.map((branch) => ({
                value: branch.id,
                label: branch.branchName,
              }))}
              isClearable
              isMulti={
                selectedRole &&
                selectedRole.value !== "Customer Relationship Officer"
              }
              onMenuOpen={fetchBranches}
              placeholder="---Select Branch---"
              className="w-100"
            />
            <button
              className="m-4 btn btn-addCredential"
              onClick={handleShowNestedModal}
            >
              +
            </button>
          </div>
          <Form.Group controlId="username">
            <Form.Label className="pt-2">Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label className="pt-2">Password</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="bg-danger btn"
            style={{ color: "white" }}
            onClick={toggleModal}
          >
            Close
          </button>

          <button className="btn btn-addCredential" onClick={handleAddLogin}>
            Add
          </button>
        </Modal.Footer>
      </Modal>
      <AddBranchModal
        show={showBranchAddModal}
        handleClose={handleCloseNestedModal}
      />
      <ToastContainer />
    </div>
  );
};

export default AddLoginCredential;
